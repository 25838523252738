window.addEventListener('load', function () {
    var cargo = new Vue({
        el: '#formCargo',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formCargo',
                description: 'Заявка на страхование грузов'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                transfer: {
                    "f-label": "Перевозка",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "transferDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                transport: {
                    "f-label": "Вид транспорта",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "transportDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                mixedDescription: {
                    "f-label": "Укажите используемые виды транспорта",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                cargo: {
                    "f-label": "Вид перевозимых грузов",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "cargoDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                cargoDescription: {
                    "f-label": "Укажите виды перевозимых грузов",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                totalSum: {
                    "f-label": "Общая стомость груза (млн.рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                averageSum: {
                    "f-label": "Средняя стоимость груза в одном транспортном средстве (млн. рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                routeFrom: {
                    "f-label": "Маршрут перевозки (от)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                routeTo: {
                    "f-label": "Маршрут перевозки (до)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                transferDic: [
                    {name: "once", description: "Разовая"},
                    {name: "contract", description: "Генеральный договор"}
                ],
                transportDic: [
                    {name: "car", description: "автомобильный"},
                    {name: "train", description: "ж/д транспорт"},
                    {name: "air", description: "авиационный"},
                    {name: "sea", description: "водный (морской)"},
                    {name: "river", description: "водный (речной)"},
                    {name: "mixed", description: "комбинированный"}
                ],
                cargoDic: [
                    {name: "equipment", description: "оборудование"},
                    {name: "car", description: "спецтехника, автомобили"},
                    {name: "food", description: "продукты питания"},
                    {name: "appliances", description: "бытовая техника"},
                    {name: "alcohol", description: "алкогольная продукция"},
                    {name: "extraConditions", description: "товары требующие особый режим перевозки (рефрижераторные риски)"},
                    {name: "computerMobile", description: "компьютерная техника или мобильные телефоны"},
                    {name: "other", description: "иное"}
                ]
            }
        },
        methods: {
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {
                
                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }    
            }            
        },
        computed: {
            watchTransfer() {
                return this.form.transfer['f-value'];
            },
            watchTransport() {
                return this.form.transport['f-value'];
            },
            watchCargo() {
                return this.form.cargo['f-value'];
            }
        },
        watch: {
            watchTransfer: function() {
                var vm = this;
                try {
                    this.form.transfer['f-plain-value'] = this.dictionaries[this.form.transfer['f-dic']] ? 
                        this.dictionaries[this.form.transfer['f-dic']].find(function(dic){
                            return dic.name === vm.form.transfer['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchTransport: function() {
                var vm = this;
                this.form.mixedDescription['f-condition'] = this.form.transport['f-value'] === 'mixed';
                this.form.mixedDescription['f-value'] = '';
                try {
                    this.form.transport['f-plain-value'] = this.dictionaries[this.form.transport['f-dic']] ? 
                        this.dictionaries[this.form.transport['f-dic']].find(function(dic){
                            return dic.name === vm.form.transport['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchCargo: function() {
                var vm = this;
                this.form.cargoDescription['f-condition'] = this.form.cargo['f-value'] === 'other';
                this.form.cargoDescription['f-value'] = '';
                try {
                    this.form.cargo['f-plain-value'] = this.dictionaries[this.form.cargo['f-dic']] ? 
                        this.dictionaries[this.form.cargo['f-dic']].find(function(dic){
                            return dic.name === vm.form.cargo['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            }
        }
    });
})